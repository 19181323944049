<template>
  <div v-if="isOpen" class="absolute top-0 left-0 w-full h-full fade">
    <div id="overlay"></div>
    <div class="z-50  absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
      <p class="font-bold text-3xl text-white">{{text}} </p>
      <p class="font-bold text-xl text-white">{{text}} Image {{ indexAt }} of {{ length }}</p>

      <div class="relative w-1/3 mx-auto mt-4">
        <div class="h-1 bg-white rounded-full"></div>
        <div class="h-1 bg-indigo-500 rounded-full absolute top-0 transition-all duration-500" :style="[percent]"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    length: {
      type: Number,
      default: 10
    },
    indexAt: {
      type: Number,
      default: 0
    },
    text:{
      type: String,
      default:"Upload"
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const isOpen = toRef(props, 'modelValue')

    const percent = computed(() => {
      const calcPercent = (props.indexAt * 100) / props.length
      return `width: ${calcPercent}%;`
    })

    return {
      isOpen,
      percent,
    }
  },
})
</script>

<style scoped>
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index:40;
  background-color: #000000db;
}
</style>
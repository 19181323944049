<template>
  <div class="relative w-full" >
    <div class="bg-black shadow-lg skeleton rounded">
      <div
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <svg
          fill="none"
          class="w-10 h-10 animate-spin text-gray-100"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
            fill="currentColor"
            fill-rule="evenodd"
          />
        </svg>
      </div>
      <div class="m-auto h-28 w-100 shadow-lg"></div>
    </div>
  </div>
</template>
